<!-- 添加回访弹窗 -->
<template>
  <el-dialog
    width="50%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'添加回访':'添加回访'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="80px">

      <el-row>
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">回访电话</span>
        </div>
      </el-row>
      <el-row :gutter="15">
        <div class="xuanxianglist">
          <div v-for="(item,index) in list" :key="index" class="kuai">
            <div class="xuanxianglistactive"  :class="{xuanxianglistactive1:index==isShow}" @click="activeItem(item,index)">
              {{item.name}}
            </div>
          </div>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">回访满意度</span>
        </div>
      </el-row>
      <el-row :gutter="15">
        <div class="group" style="margin-top: 15px;">
          <el-radio-group v-model="form.satisficing">
            <div style="display: flex;">
              <el-col v-for="(item,index) in dict.type.satisfaction" :key="index">
                <el-radio :label="parseInt(item.value)">{{item.label}}</el-radio>
              </el-col>
            </div>
            <!--<el-col :span="6">-->
            <!--  <el-radio :label="2">不满意</el-radio>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-radio :label="3">有投诉意向</el-radio>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-radio :label="4">客户已投诉</el-radio>-->
            <!--</el-col>-->
          </el-radio-group>
        </div>
      </el-row>

      <el-row style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05; display: inline-block;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;margin-left: 10px;">回访情况</span>
        </div>
      </el-row>
      <el-row :gutter="15" style="margin-top: 10px;">
        <el-checkbox-group v-model="form.case">
          <el-col :span="11" style="margin: 10px;" v-for="(item,index) in dict.type.condition" :key="index">
            <el-checkbox :label="item.label" :value="item.value"></el-checkbox>
          </el-col>
          <!--<el-col :span="11" style="margin: 10px;">-->
          <!--  <el-checkbox label="师傅已到达，对师傅有意见"></el-checkbox>-->
          <!--</el-col>-->
          <!--<el-col :span="11" style="margin: 10px;">-->
          <!--  <el-checkbox label="师傅未到达但已约好到达时间，同意给好评"></el-checkbox>-->
          <!--</el-col>-->
          <!--<el-col :span="11" style="margin: 10px;">-->
          <!--  <el-checkbox label="师傅未到达且不同意师傅预约时间"></el-checkbox>-->
          <!--</el-col>-->
          <!--<el-col :span="11" style="margin: 10px;">-->
          <!--  <el-checkbox label="未有师傅联系沟通达到时间"></el-checkbox>-->
          <!--</el-col>-->
          <!--<el-col :span="11" style="margin: 10px;">-->
          <!--  <el-checkbox label="有师傅联系但不同意师傅到达时间要求更换师傅"></el-checkbox>-->
          <!--</el-col>-->
        </el-checkbox-group>
      </el-row>

      <el-row style="margin-top: 10px; margin-bottom: 10px;">
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 5}"
          placeholder="输入回访情况"
          v-model="form.case_details">
        </el-input>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import {Savevisit} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 字典数据
    dict: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        odd:'',
        satisficing: 0,
        case:[],
        case_details:''
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //数据
      list:[
        {
          name:'拨打车主电话'
        },
        {
          name:'拨打其他电话'
        },
      ],
      isShow:0,

      radio:1,
      checkList:[],

    };
  },
  watch: {

  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.odd = this.data.order_id;
          Savevisit(this.data.id,this.form).then(res => {
            if(res.code == 200){
              this.loading = true;
              this.updateVisible(false);
              this.$emit('done');
              this.loading = false;
              this.$message.success(res.msg);
            }else {
              this.loading = false;
              this.$message.error(res.msg)
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg)
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{
  .kuai{
    margin: 10px;
    display: inline-block;
  }
  .xuanxianglistactive{
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .xuanxianglistactive:hover{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
  .xuanxianglistactive1{
    background: #fff4e3;
    border: 1px solid #ffd38e;
    border-radius: 6px;
    color: #FF9B05;
  }
}
</style>
